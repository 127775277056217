<template>
  <div class="wap-goods-list">
    <div class="flex-center" v-for="(item, i) in list" :key="i">
      <div class="check" v-if="showCheck">
        <Checkbox
          v-model="item.checked"
          icon-size="18"
          @click="checkGoods(item, i)"
        ></Checkbox>
      </div>
      <div class="list flex" @click="toDetail(item)">
        <div class="img">
          <img :src="item.MainImage" alt="" />
        </div>
        <div class="flex-1 info">
          <div class="title">
            {{ item.ProductTranslation ? item.ProductTranslation[0].Name : '' }}
          </div>
          <div class="count">
            <span>{{ $t("xiao-liang") }}{{ item.Sales }}</span>
          </div>
          <div class="price">${{ item.SalePrice }}</div>
        </div>
        <div class="action" v-if="showEdit" @click.stop="toEdit(item)">
          <i class="el-icon-more"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox } from 'vant'
export default {
  components: {
    Checkbox
  },
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showCheck: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    hideDetail: {
      type: Boolean,
      default: false
    },
    type: String
  },
  methods: {
    toDetail(data) {
      if (this.hideDetail) {
        return
      }
      let name =
        this.type == 'seller' ? 'wapProductDetail' : 'wapUserProductDetail'
      this.$router.push({
        name: name,
        query: {
          id: data.ID
        }
      })
    },
    toEdit(data) {
      this.$emit('edit', data)
    },
    checkGoods(data, i) {
      this.$emit('check', data.checked, i)
    }
  }
}
</script>