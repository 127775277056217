<template>
  <div class="wap-product">
    <div class="search flex-center-between" @click="toSearch">
      <i class="el-icon-search"></i>
      <div>{{ $t('qing-shu-ru-shang-pin-ming-cheng-0') }}</div>
      <i></i>
    </div>

    <div class="count-box flex">
      <div class="box flex-column-center" @click="toPath('wapProductLibrary')">
        <div class="count">{{ staticInfo.Stock || 0 }}</div>
        <div class="label flex-center">
          {{ $t('shang-pin-ku') }} <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="box flex-column-center" @click="toPath('wapProductEvalute')">
        <div class="count">{{ staticInfo.Reviews || 0 }}</div>
        <div class="label flex-center">
          {{ $t('ping-lun') }} <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="product-title flex-center-between">
      <div class="title">
        {{ $t('dian-pu-chan-pin') }}
        ({{ totalCount }})
      </div>
      <div class="flex-center" v-if="!showCheck" @click="toEdit">
        <i class="el-icon-s-operation"></i>
        {{ $t('pi-liang-cao-zuo') }}
      </div>
      <div class="flex-center red" v-else @click="cancel">
        <i class="el-icon-remove-outline"></i>
        {{ $t('qu-xiao-0') }}
      </div>
    </div>

    <List
      v-model="loading"
      :finished="finished"
      :finished-text="$t('common.noMore')"
      @load="onLoad"
    >
      <goodsList
        :list="goodsList"
        :showEdit="true"
        :showCheck="showCheck"
        type="seller"
        @check="checkGoods"
        @edit="editOneProduct"
      ></goodsList>
    </List>

    <div class="bottom-action flex-center-between" v-show="showCheck">
      <div class="flex-center">
        <Checkbox icon-size="18" v-model="checkedAll" @click="checkAll">{{
          $t('quan-xuan')
        }}</Checkbox>
        <span class="label"
          >{{ $t('yi-xuan') }}
          {{ checkedList.length }}
        </span>
      </div>
      <div class="btn">
        <Button class="btn1" @click="batchRemove">{{
          $t('pi-liang-shan-chu')
        }}</Button
        ><Button class="btn2" @click="batchEdit">{{
          $t('pi-liang-bian-ji')
        }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from '@/components/goodsList/wapList.vue'
import { Search, Button, Checkbox, List } from 'vant'
import {
  shopProductList,
  productStock,
  getCategoryList,
  removeProduct,
  editShopProduct
} from '@/api/shop'
import { getUserId } from '@/utils/auth'
export default {
  components: {
    Search,
    Button,
    Checkbox,
    List,
    goodsList
  },
  data() {
    return {
      showCheck: false,
      checkedAll: false,
      checkedList: [],
      goodsList: [],
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      staticInfo: {},
      totalCount: 0,
      category: ''
    }
  },
  mounted() {
    this.init()
    this.initInfo()
    let category = this.$route.query.category
    this.category = category
  },
  methods: {
    initInfo() {
      productStock().then((res) => {
        this.staticInfo = res.data
      })
    },
    searchProduct() {
      //
    },
    toEdit() {
      this.showCheck = true
    },
    cancel() {
      this.showCheck = false
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        CategoryID: this.category || null,
        UId: parseFloat(getUserId())
      })
        .then((res) => {
          if (isAdd) {
            this.goodsList = this.goodsList.concat(res.data.Items)
          } else {
            this.goodsList = res.data.Items
          }
          this.totalCount = res.data.Pagination
            ? res.data.Pagination.totalRecords
            : 0
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    checkAll() {
      this.goodsList.forEach((v, i) => {
        this.$set(this.goodsList[i], 'checked', this.checkedAll)
      })
      this.getCheckList()
    },
    checkGoods(checked, i) {
      this.goodsList[i].checked = checked
      this.getCheckList()
      if (this.checkedList.length == this.goodsList.length) {
        this.checkedAll = true
      } else {
        this.checkedAll = false
      }
    },
    getCheckList() {
      this.checkedList = this.goodsList.filter((v) => {
        return v.checked
      })
    },
    editOneProduct(data) {
      this.$router.push({
        name: 'wapEditProduct',
        query: {
          ids: data.ID,
          status: data.Status,
          type: 'one'
        }
      })
    },
    batchEdit() {
      if (this.checkedList.length == 0) {
        this.$toast(this.$t('qing-gou-xuan-shang-pin'))
        return
      }
      this.$router.push({
        name: 'wapEditProduct',
        query: {
          ids: this.checkedList
            .map((v) => {
              return v.ID
            })
            .join(),
          status: this.checkedList[0].Status
        }
      })
    },
    async batchRemove() {
      if (this.checkedList.length === 0) {
        this.$toast(this.$t('qing-gou-xuan-shang-pin'))
        return
      }
      let index = 0
      for (let i = 0; i < this.checkedList.length; i++) {
        let id = this.checkedList[i].ID
        let res = await removeProduct({
          ID: id
        })
        if (res.code == 0) {
          index += 1
          if (index == this.checkedList.length) {
            this.init()
            this.$toast(this.$t('shan-chu-cheng-gong'))
            this.checkedList = []
          }
          continue
        } else {
          this.$toast(res.msg)
          break
        }
      }
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    toSearch() {
      this.$router.push({
        name: 'wapSearch',
        query: {
          type: 1
        }
      })
    }
  }
}
</script>